




































































































import { Component, Prop, Vue } from "vue-property-decorator";
import GA from "@/app/modules/_global/classes/Analytics";
import { appId } from "@/../capacitor.config.json";
import moment from "moment";

type Order = {
  id?: number;
  status?: {
    id: number;
    name: string;
  };
};

type Status = {
  id: number;
  color: string;
};

@Component({
  filters: {
    date_entered(value, appLanguage) {
      if (!value) return;

      return moment(value)
        .locale(appLanguage)
        .format("Do MMMM, Y");
    }
  }
})
export default class OrderPage extends Vue {
  @Prop(Number) readonly orderId!: number;

  constructor() {
    super();

    GA.addCustomDimension(1, `${this.userData?.user.id}`);
    GA.trackView(`Order: ${this.orderId}`);
  }

  private orderStatuses: Status[] = [
    { id: 1, color: "warning" }, // open
    { id: 2, color: "success" }, // confirmed
    { id: 3, color: "info" }, // assigned
    { id: 4, color: "info" }, // in progress
    { id: 5, color: "info" }, // reported
    { id: 6, color: "grey" } // cancelled
  ];

  private order: null | Order = null;
  cancelDialog = false;

  created() {
    this.fetchOrderById();
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  get appLanguage() {
    return this.$store.getters["app/language"];
  }

  get statusChipColor(): string {
    const status: undefined | Status = this.orderStatuses.find(
      (status: Status) => status.id == this.order?.status?.id
    );

    return status?.color ?? "info";
  }

  fetchOrderById() {
    this.$store.dispatch("orders/fetchById", this.orderId).then(({ data }) => (this.order = data));
  }

  cancelOrder() {
    const data = {
      bundle: appId,
      id: this.orderId
    };

    this.$store.dispatch("orders/cancel", data).then(() => {
      this.fetchOrderById();
      this.cancelDialog = false;
    });
  }
}
